import { css, cx } from '@emotion/css';
import React, { FC } from 'react';

import { colorManipulator } from '@grafana/data';
import { useTheme2, styleMixins } from '@grafana/ui';

export interface BrandComponentProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

export const LoginLogo: FC<BrandComponentProps & { logo?: string }> = ({ className, logo }) => {
  return <img className={className} src={`${logo ? logo : 'public/img/grafana_icon.svg'}`} alt="Grafana" />;
};

const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  const theme = useTheme2();
  const side = css`
    display: none;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-image: url('public/img/login-bg.jpeg');
      background-position: right;
      background-size: contain;
      background-repeat: no-repeat;
      filter: grayscale(100%);
    }
    @media ${styleMixins.mediaUp(theme.v1.breakpoints.lg)} {
      display: inline-block;
    }
  `;
  const cont = css`
    display: flex;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right top, #444649, #333638, #242727, #171817, #040403);
    position: relative;
    margin: 0 auto;
    @media ${styleMixins.mediaUp(theme.v1.breakpoints.lg)} {
      display: grid;
    }
  `;
  const background = css`
    margin: 0 auto;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-position: top center;
      background-size: auto;
      background-repeat: no-repeat;
      opacity: 0;

      transition: opacity 3s ease-in-out;
      @media ${styleMixins.mediaUp(theme.v1.breakpoints.md)} {
        background-position: center;
        background-size: cover;
      }
    }
  `;

  return (
    <div className={cx(cont)}>
      <div className={cx(background, className)}>{children}</div>
      <div className={cx(side)}></div>
    </div>
  );
};

const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/grafana_icon.svg" alt="Grafana" />;
};

const LoginBoxBackground = () => {
  const theme = useTheme2();
  return css`
    background: ${colorManipulator.alpha(theme.colors.background.primary, 0.7)};
    background-size: cover;
  `;
};

export class Branding {
  static LoginLogo = LoginLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = 'Cosumar Digital Process';
  static LoginTitle = 'DIGITAL PROCESS';
  static GetLoginSubTitle = (): null | string => {
    return 'COSUMAR INDUSTRY 4.0';
  };
}
